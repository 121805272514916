import React, { useEffect, useState } from "react";
import { Image, Modal, Segment } from "semantic-ui-react";
import { Button, Header, ModalProfileDetail } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirmOrderMutation, usePrepareOrderMutation } from "../services/OrderService";
import { useUserDetailMutation } from "../services/UserService";
import { birthTimeList, genderList } from "../utils/Name";

const SaleScreen = () => {
    const navigate = useNavigate();

    const { orderId } = useParams()
    const [userDetail, result_user_detail] = useUserDetailMutation()
    const [confirmOrder] = useConfirmOrderMutation()
    const [prepareOrder, result_prepare_order] = usePrepareOrderMutation()
    const [is_open, setIsOpen] = useState(false)
    const [detail, setDetail] = useState("")
    const [error, setError] = useState(false)
    const [errorStock, setErrorStock] = useState(false)

    const getData = async () => {
        try {
            localStorage.removeItem("orderId")
            const _result_prepare = await prepareOrder({
                products: [
                    {
                        productId: orderId,
                        amount: 1
                    }
                ],
                discountCode: null
            }).unwrap()

            if (_result_prepare.status !== "0000") {
                navigate("/", { replace: true })
            }
        } catch (e) {

        }
    }

    const getUser = async () => {
        try {
            await userDetail().unwrap()
        } catch {

        }
    }

    const buyProduct = async () => {
        if (detail) {
            const result = await confirmOrder(
                {
                    products: [
                        {
                            productId: orderId,
                            amount: 1
                        }
                    ],
                    deliveryDetail: {
                        firstName: detail.firstName,
                        lastName: detail.lastName,
                        birthDate: detail.birthDate,
                        gender: detail.gender,
                        tel: detail.tel,
                        birthTime: detail.birthTime
                    }
                }).unwrap()
            if (result.status === "0000") {
                console.log(result?.data)
                navigate(`/success-buy-card/${result?.data?._id}`, { replace: true })
            } else {
                setErrorStock(true)
            }
        } else {
            setError(true)
        }

    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <Segment
            loading={result_prepare_order.isLoading}
            style={{ boxShadow: 'unset', border: 0, background: 'transparent' }}
        >
            {
                result_prepare_order?.data?.data &&
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "calc(100vh + 110px)",
                    }}
                >
                    <Header>ดูดวงเปิดไพ่</Header>

                    <Image
                        src={result_prepare_order?.data?.data?.product[0]?.images?.filter((item) => item.isMain == true)[0]?.path ? result_prepare_order?.data?.data?.product[0]?.images?.filter((item) => item.isMain == true)[0]?.path : require("../assets/images/auth/card.png")}
                        style={{ maxHeight: 300, height: "100%" }}
                    />

                    <center
                        style={{
                            color: "#fff",
                            padding: "20px 0px 10px",
                            fontSize: 12
                        }}
                    >
                        {result_prepare_order?.data?.data?.product[0]?.name}
                    </center>

                    <center
                        style={{
                            color: "#777777",
                            fontSize: 12
                        }}
                    >
                        {result_prepare_order?.data?.data?.product[0]?.detail}
                    </center>

                    <center
                        style={{
                            fontSize: 22,
                            color: "#E0D6B3",
                            fontWeight: 300,
                            padding: "20px 0px"
                        }}
                    >
                        เปิดไพ่ทำนายชะตาให้
                    </center>

                    {
                        result_prepare_order?.data?.data?.product[0]?.status === "ACTIVE" ?
                            <>
                                {
                                    detail ?
                                        <>
                                            <div style={{ marginTop: -10, cursor: "pointer" }}>
                                                <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>ชื่อ-นามสกุล : {detail.firstName}-{detail.lastName}</div>
                                                <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>วันเดือนปีเกิด : {detail.birthDate}</div>
                                                <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เพศ : {genderList?.filter((result) => result.value === detail.gender)[0]?.key}</div>
                                                <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เวลาเกิด : {birthTimeList?.filter((result) => result.value === detail.birthTime)[0]?.key}</div>
                                                <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เบอร์โทรศัพท์ : {detail.tel}</div>
                                            </div>
                                            <Button
                                                style={{ width: 200, marginTop: 10 }}
                                                onClick={() => {
                                                    getUser()
                                                    setIsOpen(true)
                                                    setError(false)
                                                }}
                                            >
                                                เลือกข้อมูลผู้ใช้อื่น
                                            </Button>
                                        </>
                                        :
                                        <Button
                                            style={{ width: 200 }}
                                            onClick={() => {
                                                getUser()
                                                setIsOpen(true)
                                                setError(false)
                                            }}
                                        >
                                            เลือกข้อมูลผู้ใช้
                                        </Button>
                                }

                                {result_prepare_order?.data?.data?.product[0]?.discountPrice > 0 &&
                                    <center
                                        style={{
                                            fontSize: 18,
                                            color: "#777777",
                                            fontWeight: 400,
                                            textDecoration: "line-through"
                                        }}
                                    >
                                        ฿ {result_prepare_order?.data?.data?.product[0]?.price}
                                    </center>
                                }

                                <center
                                    style={{
                                        fontSize: 22,
                                        color: "#E0D6B3",
                                        fontWeight: 400,
                                        padding: "0px 0px 10px"
                                    }}
                                >
                                    ฿ {result_prepare_order?.data?.data?.product[0]?.finalPrice}
                                </center>

                                {
                                    error &&
                                    <center style={{ color: "red", marginBottom: 10, fontSize: 16 }}>* กรุณาเลือกผู้ใช้งาน</center>
                                }

                                <div>
                                    {
                                        result_prepare_order?.data?.data?.product[0]?.stock === 0 || errorStock ?
                                            <center
                                                style={{
                                                    fontSize: 22,
                                                    color: "#E0D6B3",
                                                    fontWeight: 300,
                                                }}
                                            >
                                                สินค้าหมด
                                            </center>
                                            :
                                            <Button
                                                style={{ backgroundColor: "rgba(171, 141, 96, 1)" }}
                                                onClick={() => {
                                                    if (result_prepare_order?.data?.data?.product[0]?.stock !== 0)
                                                        buyProduct()
                                                }}
                                            >
                                                สั่งซื้อ
                                            </Button>
                                    }

                                </div>
                            </>
                            :
                            <center
                                style={{
                                    fontSize: 22,
                                    color: "#E0D6B3",
                                    fontWeight: 300,
                                }}
                            >
                                สินค้าหมด
                            </center>
                    }

                    {
                        result_user_detail?.data?.data?.profiles.length > 0 ?
                            <Modal
                                onClose={() => setIsOpen(false)}
                                open={is_open}
                                style={{
                                    background: 'transparent',
                                    maxWidth: 400,
                                }}
                                closeIcon={true}
                            >
                                <Header>ข้อมูลผู้ใช้งาน</Header>

                                <>
                                    {
                                        result_user_detail?.data?.data?.profiles?.map((item) => {
                                            return (
                                                <div
                                                    style={{
                                                        backgroundColor: "rgba(171, 141, 96, 0.5)",
                                                        border: "2px solid #AB8D60",
                                                        borderRadius: 20,
                                                        marginBottom: 10,
                                                        padding: 10,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                        setDetail(item)
                                                        setIsOpen(false)
                                                    }}
                                                >
                                                    <div>
                                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>ชื่อ-นามสกุล : {item.firstName}-{item.lastName}</div>
                                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>วันเดือนปีเกิด : {item.birthDate}</div>
                                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เพศ : {genderList?.filter((result) => result.value === item.gender)[0]?.key}</div>
                                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เวลาเกิด : {birthTimeList?.filter((result) => result.value === item.birthTime)[0]?.key}</div>
                                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เบอร์โทรศัพท์ : {item.tel}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                                <center>
                                    <Button
                                        style={{ backgroundColor: "rgba(171, 141, 96, 1)", width: 200, marginTop: 20 }}
                                        onClick={() => navigate("/manage-profile")}
                                    >
                                        เพิ่มข้อมูลผู้ใช้งาน
                                    </Button>
                                </center>
                            </Modal>
                            :
                            result_user_detail.isSuccess &&
                            <ModalProfileDetail
                                isOpen={is_open}
                                onClose={() => { setIsOpen(false) }}
                                onsubmit={(item) => {
                                    setDetail(item)
                                    setIsOpen(false)
                                    getData()
                                }}
                            />
                    }


                </div>
            }

        </Segment>
    )
}

export default SaleScreen