import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    BASE_PATH,
    LOG_IN,
    REGISTER,
    RESET_PASSWORD,
    SEND_OTP,
    SEND_OTP_FORGET_PASSWORD,
    VERIFY_OTP,
} from '../constants/PathConfig';

const AuthService = createApi({
    reducerPath: 'AuthService',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_PATH }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: LOG_IN,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data.message === "success") {
                        localStorage.setItem("access_token", data.data.token)
                    }
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        sendOtp: builder.mutation({
            query: (data) => ({
                url: SEND_OTP,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        verifyOtp: builder.mutation({
            query: (data) => ({
                url: VERIFY_OTP,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        register: builder.mutation({
            query: (data) => ({
                url: REGISTER,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        sendOtpForgetPassword: builder.mutation({
            query: (data) => ({
                url: SEND_OTP_FORGET_PASSWORD,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: RESET_PASSWORD,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
    })
});

export const {
    useLoginMutation,
    useRegisterMutation,
    useResetPasswordMutation,
    useSendOtpForgetPasswordMutation,
    useSendOtpMutation,
    useVerifyOtpMutation
} = AuthService;

export default AuthService;
