import { createApi } from '@reduxjs/toolkit/query/react';
import {
    CONFIRM_ORDER,
    GET_ORDER_DETAIL,
    GET_ORDER_LIST,
    GET_PAYMENT_DATA,
    PREPARE_ORDER,
} from '../constants/PathConfig';
import FetchService from "./FetchService";

const OrderService = createApi({
    reducerPath: 'OrderService',
    baseQuery: FetchService,
    endpoints: (builder) => ({
        getOrderList: builder.mutation({
            query: () => ({
                url: GET_ORDER_LIST,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        getOrderDetail: builder.mutation({
            query: (data) => ({
                url: GET_ORDER_DETAIL + data,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        prepareOrder: builder.mutation({
            query: (data) => ({
                url: PREPARE_ORDER,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        confirmOrder: builder.mutation({
            query: (data) => ({
                url: CONFIRM_ORDER,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        getPaymentData: builder.mutation({
            query: (data) => ({
                url: GET_PAYMENT_DATA + data,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    console.log("data", data)
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
    }),
});

export const {
    useGetOrderDetailMutation,
    useGetOrderListMutation,
    useConfirmOrderMutation,
    useGetPaymentDataMutation,
    usePrepareOrderMutation
} = OrderService;

export default OrderService;
