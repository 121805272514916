export const BASE_PATH = process.env.REACT_APP_BASE_API || "https://api.saimoo.net"

// login
export const LOG_IN = "/user/auth/signin"
export const LOG_OUT = "/user/logout"
export const CHANGE_PASSWORD = "/user/auth/changpassword"

// register
export const SEND_OTP = "/user/auth/signup/sendotp"
export const VERIFY_OTP = "/user/auth/verifyOTP"
export const REGISTER = "/user/auth/signup"

// forget password
export const SEND_OTP_FORGET_PASSWORD = "/user/auth/resetpassword/sendotp"
export const RESET_PASSWORD = "/user/auth/resetpassword"

// user 
export const USER_ME = "/user/me"
export const DELETE_PROFILE = "/user/profile"
export const ADD_PROFILE = "/user/profile"

// product
export const ALL_PRODUCT = "/product/all/" //:storeId
export const PRODUCT_DETAIL = "/product/detail/" //:productId

// shopping
export const PREPARE_ORDER = "/shop/prepareOrder"
export const CONFIRM_ORDER = "/shop/confirmOrder"
export const GET_PAYMENT_DATA = "/shop/payment/paysolutions?orderId=" //646c2bd7f8a7974a6ce7fdd0
export const GET_ORDER_LIST = "/shop/myOrder"
export const GET_ORDER_DETAIL = "/shop/myOrder/" //:orderId

// game card 
export const LOAD_ENV_GAME = "/gameCard/loadEnv"
export const GAME_PLAY = "/gameCard/play"