import React, { useEffect, useState } from "react";
import { Grid, Image, Segment } from "semantic-ui-react";
import { Button, Header } from "../components";
import { useGamePlayMutation, useLoadEnvGameMutation } from "../services/GameService";
import { useNavigate, useParams } from "react-router-dom";

const OpenCardScreen = () => {
    const navigate = useNavigate()

    const { orderId, productId } = useParams();

    const [loadEnvGame, result_env_game] = useLoadEnvGameMutation()
    const [gamePlay, result_game_play] = useGamePlayMutation()

    const [select_list, setSelectList] = useState([])

    const getData = async () => {
        try {
            const result = await loadEnvGame({
                orderId,
                productId
            }).unwrap()
            if (result.status === "0000") {
                console.log(result)
            } else {
                navigate("/list-order")
            }
        } catch {

        }
    }

    const handleSubmit = async () => {
        try {
            if (select_list.length === result_env_game?.data?.data?.playCard && !result_game_play.isLoading) {
                const result_play = await gamePlay({
                    productId: productId,
                    orderId
                }).unwrap()
                if (result_play.status === "0000") {
                    navigate("/result-card/" + orderId)
                } else {
                    navigate("/result-card/" + orderId)
                }
            }
        } catch (error) {

        }

    }

    useEffect(() => {
        getData()
    }, [])

    return <div
        style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 90,
        }}
    ><Segment
        style={{
            boxShadow: 'unset',
            border: 0,
            background: 'transparent',
            maxWidth: 400,
            margin: 0,
        }}
        loading={result_env_game?.isLoading}
    >
            <Header>ดูดวงเปิดไพ่</Header>
            <Grid
                columns={result_env_game?.data?.data?.cards?.length > 5 ? 5 : result_env_game?.data?.data?.cards?.length}
            >
                {
                    result_env_game?.data?.data?.cards?.map((_, index) => {
                        return <Grid.Column style={{ padding: "0.5rem", cursor: "pointer" }}>
                            <Image
                                src={result_env_game?.data?.data.imageBack ? result_env_game?.data?.data.imageBack : require("../assets/images/card.png")}
                                onClick={() => {
                                    if (select_list?.findIndex((item) => item === index) !== -1) {
                                        const result_list = select_list.filter(items => items !== index)
                                        setSelectList([...result_list])
                                    } else if (result_env_game?.data?.data?.playCard > (select_list?.length | 0)) {
                                        setSelectList([...select_list, index])
                                    }
                                }}
                            />
                            <div
                                style={select_list?.findIndex((item) => item === index) !== -1 ? { display: "block", width: "calc(100% - 1rem)", height: "calc(100% - 1rem)", background: "#FFF", position: "absolute", left: "0.5rem", top: "0.5rem", opacity: 0.5 } : { display: "none" }}
                                onClick={() => {
                                    if (select_list?.findIndex((item) => item === index) !== -1) {
                                        const result_list = select_list.filter(items => items !== index)
                                        setSelectList([...result_list])
                                    } else if (result_env_game?.data?.data?.playCard > (select_list?.length | 0)) {
                                        setSelectList([...select_list, index])
                                    }
                                }} />
                        </Grid.Column>
                    })
                }
            </Grid>

            {
                result_env_game?.data?.data &&
                <center>
                    <div
                        style={{
                            fontSize: 18,
                            color: "#E0D6B3",
                            fontWeight: 300,
                            padding: "10px 0px",
                            marginTop: 20
                        }}
                    >เลือกไพ่ {result_env_game?.data?.data?.playCard} ใบ</div>
                    <Button style={{ backgroundColor: "#8b7451", color: "#fff", width: 150 }} onClick={handleSubmit} >เปิดไพ่</Button>
                </center>
            }

        </Segment>
    </div >
}

export default OpenCardScreen