import { useRouteError } from "react-router-dom";
import { Container } from "semantic-ui-react";

export default function ErrorScreen() {
  const error = useRouteError();

  return (
    <div id="error-page">
      <Container style={{ height: "100vh", display: "flex" }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flex: 1
        }}>
          <h1 style={{ color: "rgba(255, 255, 255, 1)", fontSize: 48 }}>เกิดข้อผิดพลาด</h1>
          <p style={{ color: "rgba(255, 255, 255, 1)", fontSize: 24 }}> กรุณาลองใหม่ภายหลัง.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </Container>
    </div>
  );
}