import React from "react";
import { Icon } from "semantic-ui-react";

import { useNavigate } from 'react-router-dom';

const Header = ({ children, canGoBack, style }) => {
    const navigate = useNavigate();

    return <center className="header-custom" style={style} >
        {
            canGoBack &&
            <Icon
                name="arrow left"
                style={{
                    color: "#E4A951",
                    fontSize: 24,
                    position: "absolute",
                    left: 20,
                    cursor: "pointer"
                }}
                onClick={() => navigate(-1)}
            />
        }
        {children}
    </center>
}

export default Header