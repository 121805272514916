import React, { useEffect, useState } from "react";
import { Button as ButtonSemantic, Icon, Modal, Segment } from "semantic-ui-react";
import { Header, Button } from "../components"
import { useDeleteUserMutation, useUserDetailMutation } from "../services/UserService";
import ModalProfileDetail from "../components/ModalProfileDetail";
import { birthTimeList, genderList } from "../utils/Name";

const ManageProfileScreen = () => {
    const [is_open, setIsOpen] = useState(false)
    const [userDetail, result_user_detail] = useUserDetailMutation()
    const [deleteUser, result_delete_user] = useDeleteUserMutation()
    const [is_open_confirm, setOpenConfirm] = useState(false)
    const [delete_id, setDeleteId] = useState("")

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            await userDetail().unwrap()
        } catch {

        }
    }

    const handleDelete = async (id) => {
        try {
            const _result_delete = await deleteUser({
                profileId: id
            }).unwrap()
            if (_result_delete.status === "0000") {
                getData()
            }
        } catch {

        }
    }

    return <Segment
        loading={result_user_detail.isLoading || result_delete_user.isLoading}
        style={{ boxShadow: 'unset', border: 0, background: 'transparent' }}
    >
        <Header canGoBack>จัดการผู้ใช้</Header>

        {
            result_user_detail?.data?.data?.profiles.length > 0 ?
                <>
                    {
                        result_user_detail?.data?.data?.profiles?.map((item) => {
                            return (
                                <div
                                    style={{
                                        backgroundColor: "rgba(171, 141, 96, 0.5)",
                                        border: "2px solid #AB8D60",
                                        borderRadius: 20,
                                        marginBottom: 10,
                                        padding: 10,
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>ชื่อ-นามสกุล : {item.firstName}-{item.lastName}</div>
                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>วันเดือนปีเกิด : {item.birthDate}</div>
                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เพศ : {genderList?.filter((result) => result.value === item.gender)[0]?.key}</div>
                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เวลาเกิด : {birthTimeList?.filter((result) => result.value === item.birthTime)[0]?.key}</div>
                                        <div style={{ color: "rgba(224, 214, 179, 1)", fontSize: 14 }}>เบอร์โทรศัพท์ : {item.tel}</div>
                                    </div>
                                    <Icon
                                        onClick={() => {
                                            setDeleteId(item._id)
                                            setOpenConfirm(true)
                                        }}
                                        name='trash alternate outline'
                                        size={"big"}
                                        style={{ cursor: "pointer", color: "#fff" }}
                                    />
                                </div>
                            )
                        })
                    }
                </>
                :
                <center style={{
                    color: "#fff",
                    fontSize: 18,
                    marginBottom: 15
                }}>{(result_user_detail.isLoading || result_delete_user.isLoading) ? "กำลังโหลด" : "ไม่มีข้อมูลผู้ใช้งาน"}</center>
        }

        <Button onClick={() => { setIsOpen(true) }}>เพิ่มผู้ใช้งาน</Button>
        <ModalProfileDetail
            isOpen={is_open}
            onClose={() => { setIsOpen(false) }}
            onsubmit={() => {
                setIsOpen(false)
                getData()
            }}
        />
        <Modal
            basic
            onClose={() => setOpenConfirm(false)}
            onOpen={() => setOpenConfirm(true)}
            open={is_open_confirm}
            size='mini'
        >
            <Header icon>
                ยืนยันการลบข้อมูลผู้ใช้งาน
            </Header>
            <Modal.Actions>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <ButtonSemantic
                        color='green'
                        inverted
                        onClick={() => {
                            setOpenConfirm(false)
                            handleDelete(delete_id)
                        }}
                        style={{ width: 150 }}
                    >
                        <Icon name='checkmark' /> ตกลง
                    </ButtonSemantic>
                    <ButtonSemantic color='red' inverted onClick={() => setOpenConfirm(false)} style={{ width: 150 }}>
                        <Icon name='remove' /> ยกเลิก
                    </ButtonSemantic>
                </div>
            </Modal.Actions>
        </Modal>
    </Segment>
}

export default ManageProfileScreen