import React, { useState } from 'react'
import { Button, Form, Image, } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../constants/Config";
import { useLoginMutation, useResetPasswordMutation, useSendOtpForgetPasswordMutation, useVerifyOtpMutation } from '../services/AuthService';

const ForgotPasswordScreen = () => {

    const navigate = useNavigate()

    const [resetState, setResetState] = useState(1);
    const [tel, setTel] = useState("");

    const [otpRef, setOtpRef] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [otpVerifyID, setOtpVerifyID] = useState("");

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submitText, setSubmitText] = useState("ขอ OTP");

    const [msgValidate, setMsgValidate] = useState("");
    const [showMsgValidate, setShowMsgValidate] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState("");

    const [sendOtpForgetPassword, result_send_otp] = useSendOtpForgetPasswordMutation()
    const [verifyOtp, result_verify_otp] = useVerifyOtpMutation()
    const [resetPassword, result_reset_password] = useResetPasswordMutation()
    const [login, result_login] = useLoginMutation()

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (resetState === 1) {
            const _result_send_top = await sendOtpForgetPassword({
                phoneNumber: tel,
                captcha: recaptchaValue
            }).unwrap()
            if (_result_send_top.status === "0000") {
                setOtpRef(_result_send_top.data.refcode)
                setResetState(2);
                setSubmitText("ยืนยัน");
                setShowMsgValidate(false)
            } else {
                setMsgValidate(_result_send_top.message)
                setShowMsgValidate(true)
            }
        } else if (resetState === 2) {
            const _result_verify = await verifyOtp({
                phoneNumber: tel,
                refcode: otpRef,
                verifycode: otpCode
            }).unwrap()
            if (_result_verify.status === "0000") {
                setOtpVerifyID(_result_verify.data._id)
                setResetState(3);
                setSubmitText("รีเซตรหัสผ่าน");
                setShowMsgValidate(false)
            } else {
                setMsgValidate(_result_verify.message)
                setShowMsgValidate(true)
            }
        } else if (resetState === 3) {
            const _result_reset = await resetPassword({
                username: tel,
                newPassword: password,
                otpVerifyID: otpVerifyID
            }).unwrap()
            if (_result_reset.status === "0000") {
                //login
                const _result_login = await login({
                    username: tel,
                    password: password
                }).unwrap()
                if (_result_login.message === "success") {
                    navigate("/", { replace: true })
                } else {
                    setMsgValidate(_result_login.message)
                    setShowMsgValidate(true)
                }
            } else {
                setMsgValidate(_result_reset.message)
                setShowMsgValidate(true)
            }
        }
    };

    const validateForm = () => {
        if (resetState === 3) {
            return tel.length === 10 && password === confirmPassword && password.length >= 6;
        }
        if (resetState === 2) {
            return tel.length === 10;
        }
        if (resetState === 1) {
            return tel.length === 10 && !(recaptchaValue === "" || recaptchaValue == null);
        }
        return tel.length === 10 && password === confirmPassword;
    };

    return (
        <div className="login-container">

            <div className="login-container-content">
                <Image
                    src={require("../assets/images/auth/logo_login.png")}
                    className="logo-login"
                />

                <Form loading={result_send_otp.isLoading || result_verify_otp.isLoading || result_reset_password.isLoading || result_login.isLoading}>

                    <div className="login-content">

                        <Image
                            src={require("../assets/images/auth/background_content_login.png")}
                            className="login-background"
                        />


                        <div className='topic-login'>ลืมรหัสผ่าน</div>
                        <center><p style={{ marginBottom: '5px', color: "red", marginTop: -20, display: showMsgValidate ? "block" : "none" }}>* {msgValidate}</p></center>

                        <Form.Field style={{ display: resetState === 2 || resetState === 1 ? 'inherit' : 'none' }}>
                            <input className="login-input" placeholder='เบอร์โทรศัพท์' disabled={resetState === 2} onChange={e => setTel(e.target.value)} />
                            <Form.Field style={{ display: resetState === 1 ? 'inherit' : 'none' }}>
                                <center>
                                    <ReCAPTCHA style={{ marginTop: 15 }} size='normal'
                                        sitekey={RECAPTCHA_SITE_KEY}
                                        onChange={(value) => setRecaptchaValue(value)}
                                    />
                                </center>
                            </Form.Field>
                        </Form.Field>
                        <Form.Field style={{ display: resetState === 2 ? 'inherit' : 'none' }}>
                            <label>OTP (หมายเลขอ้างอิง : {otpRef})</label>
                            <input className="login-input" placeholder='หมายเลข OTP จาก SMS' type='text' onChange={e => setOtpCode(e.target.value)} />
                        </Form.Field>
                        <Form.Field style={{ display: resetState === 3 ? 'inherit' : 'none' }}>
                            <input className="login-input" placeholder='รหัสผ่านใหม่' type='password' onChange={e => setPassword(e.target.value)} />
                        </Form.Field>
                        <Form.Field style={{ display: resetState === 3 ? 'inherit' : 'none' }}>
                            <input className="login-input" placeholder='กรอกรหัสผ่านอีกครั้ง' type='password' onChange={e => setConfirmPassword(e.target.value)} />
                        </Form.Field>
                        <Button type='submit' className="button-login" style={{ marginBottom: '1rem' }} disabled={!validateForm()} onClick={(event) => handleSubmit(event)}>{submitText}</Button>
                        <br />
                        <Button onClick={() => navigate("/")} style={{ background: '#999' }} className="button-register" type='button' fluid>
                            ยกเลิก
                        </Button>
                    </div>
                </Form>
            </div>
        </div >
    );
}

export default ForgotPasswordScreen;