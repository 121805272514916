import React, { useState } from "react";
import { Segment } from "semantic-ui-react";
import { Button, Header, Input } from "../components"
import { useChangePasswordMutation } from "../services/UserService";
import { useNavigate } from "react-router-dom";

const ChangePasswordScreen = () => {
    const navigate = useNavigate()

    const [old_password, setOldPassword] = useState("")
    const [new_password, setNewPassword] = useState("")


    const [changePassword, result_change_password] = useChangePasswordMutation()

    const handleChangePassword = async (e) => {
        try {
            if (old_password && new_password) {
                const result = await changePassword({
                    oldPassword: old_password,
                    newPassword: new_password
                }).unwrap()
                if (result.status === "0000") {
                    localStorage.setItem("access_token", result.data.token)
                    navigate("/profile")
                }
            }
        } catch (error) {

        }

    }
    return <Segment
        style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: 'unset',
            border: 0,
            background: 'transparent'
        }}
        loading={result_change_password.isLoading}
    >
        <Header canGoBack>เปลี่ยนรหัสผ่าน</Header>

        {
            result_change_password?.data?.message &&
            <p className="error_message"> * {result_change_password?.data?.message} *</p>
        }
        
        <Input
            placeholder="รหัสผ่านเดิม"
            style={{
                width: "100%",
                maxWidth: 350,
                marginBottom: 10
            }}
            type="password"
            onChange={(e) => {
                setOldPassword(e.target.value)
            }}
        />
        <Input
            placeholder="รหัสผ่านใหม่"
            style={{
                width: "100%",
                maxWidth: 350,
                marginBottom: 30
            }}
            type="password"
            onChange={(e) => {
                setNewPassword(e.target.value)
            }}
        />


        <Button
            style={{
                maxWidth: 330,
                backgroundColor: "#AB8D60"
            }}
            onClick={handleChangePassword}
            disable
        >ยืนยัน</Button>
    </Segment>
}

export default ChangePasswordScreen