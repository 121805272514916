import { createApi } from '@reduxjs/toolkit/query/react';
import {
    GAME_PLAY,
    LOAD_ENV_GAME,
} from '../constants/PathConfig';
import FetchService from "./FetchService";

const GameService = createApi({
    reducerPath: 'GameService',
    baseQuery: FetchService,
    endpoints: (builder) => ({
        loadEnvGame: builder.mutation({
            query: (data) => ({
                url: LOAD_ENV_GAME,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        gamePlay: builder.mutation({
            query: (data) => ({
                url: GAME_PLAY,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
    }),
});

export const {
    useGamePlayMutation,
    useLoadEnvGameMutation
} = GameService;

export default GameService;
