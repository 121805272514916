import React from "react";
import Button from "./Button";
import { Image } from "semantic-ui-react";

const CardOrder = ({
    title,
    from,
    user,
    onClick,
    buttonName
}) => {
    return <div style={{
        backgroundColor: "rgba(171, 141, 96, 0.5)",
        border: "2px solid #AB8D60",
        borderRadius: 20,
        marginBottom: 10,
        padding: 10
    }}
    >
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 5
        }}>
            <Image
                src={require("../assets/images/auth/group_card_2.png")}
                style={{
                    width: 63,
                    height: 57,
                    marginLeft: 20
                }}
            />
            <div style={{ marginLeft: 10 }}>
                <div style={{
                    color: "rgba(224, 214, 179, 1)",
                    fontSize: 18
                }}>
                    {title}
                </div>
                <div style={{
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: 14,
                    padding: "3px 0px"
                }}>
                    โดย {from}
                </div>
                <div style={{
                    color: "rgba(0, 0, 0, 1)",
                    fontSize: 14
                }}>
                    ทำนายชะตาให้คุณ {user}
                </div>
            </div>
        </div>
        {/* ชำระเงิน เปิดไพ่ ดูผลการเปิดไพ่ */}
        {
            buttonName &&
            <Button
                style={
                    buttonName === "ชำระเงิน" ? { borderRadius: 8, backgroundColor: "#1F1F1F", padding: 10, color: "#E4A951", border: "2px solid #E4A951" } :
                        (
                            buttonName === "เปิดไพ่" ? { borderRadius: 8, backgroundColor: "rgba(255, 255, 255, 0.5)", padding: 10, color: "#1F1F1F", border: "2px solid #000000" } :
                                (
                                    buttonName === "ดูผลการเปิดไพ่" ? { borderRadius: 8, backgroundColor: "rgba(0, 0, 0, 0.5)", padding: 10, color: "#FFFFFF", border: "2px solid #ffffff" } :
                                        { borderRadius: 8, backgroundColor: "rgba(171, 141, 96, 0.5)", padding: 10 }
                                )
                        )

                }
                onClick={onClick}>
                {buttonName}
            </Button>
        }

    </div >
}

export default CardOrder