import { Navigate, Outlet } from "react-router-dom";

export const AuthLayout = () => {
    const token = localStorage.getItem("access_token")

    if (token) {
        return <Navigate to="/home" />;
    }

    return (
        <div>
            <Outlet />
        </div>
    )
};