import React, { useEffect, useState } from 'react'
import { Form, Image } from 'semantic-ui-react'
import { Button } from "../components"
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../services/AuthService';
import { useUserDetailMutation } from '../services/UserService';

const LoginScreen = () => {

    const [tel, setTel] = useState("");
    const [password, setPassword] = useState("");
    const [msgValidate, setMsgValidate] = useState("");
    const [showMsgValidate, setShowMsgValidate] = useState(false);
    const [login, result_login] = useLoginMutation()
    const [userDetail, result_user_detail] = useUserDetailMutation()
    const navigate = useNavigate()

    const handleLogin = async () => {
        try {
            if (tel !== "" && password !== "") {
                const _result_login = await login({
                    username: tel,
                    password: password
                }).unwrap()
                if (_result_login.message === "success") {

                    if (localStorage.getItem("orderId")) {
                        navigate("/sale/" + localStorage.getItem("orderId"), { replace: true })
                    } else {
                        navigate("/", { replace: true })
                    }
                } else {
                    setMsgValidate(_result_login.message)
                    setShowMsgValidate(true)
                }
            }

        } catch (error) {
            console.log(error)
        }

    };

    const handleRegister = () => {
        navigate("/register", { replace: true })
    };

    const checkUser = async () => {
        try {
            const _result_me = await userDetail().unwrap()
            if (_result_me.message === "success") {
                navigate("/list-order", { replace: true })
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (localStorage.getItem("access_token")) {
            checkUser()
        }
    }, [])

    return (
        <div className="login-container">

            <div className="login-container-content">

                <Image
                    src={require("../assets/images/auth/logo_login.png")}
                    className="logo-login"
                />

                <Form loading={result_login.isLoading || result_user_detail.isLoading}>
                    <div className="login-content">

                        <Image
                            src={require("../assets/images/auth/background_content_login.png")}
                            className="login-background"
                        />

                        <div className='topic-login'> <center style={{ position: "absolute", bottom: -30, fontSize: 14 }}><p style={{ marginBottom: '5px', color: "red", display: showMsgValidate ? "block" : "none" }}>* {msgValidate}</p></center>ลงชื่อเข้าใช้</div>

                        <Form.Field>
                            <input
                                className="login-input"
                                placeholder='เบอร์โทร'
                                onChange={e => setTel(e.target.value.trim())}
                                value={tel}
                            />
                        </Form.Field>
                        <Form.Field>
                            <input
                                className="login-input"
                                placeholder='รหัสผ่าน'
                                type={'password'}
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                            />
                        </Form.Field>
                        <div style={{ width: '100%', display: 'flex', margin: '10px 0px', alignItems: "center" }} >
                            <p style={{ marginInlineEnd: 3, color: "#fff", marginBottom: 0, marginTop: 1 }}>คลิกที่นี่หาก</p>
                            <a href='/forget-password' style={{ "text-decoration": "underline", color: "rgba(139, 116, 81, 1)" }}>ลืมรหัสผ่าน</a>
                        </div>
                        <Button
                            className='button-login'
                            onClick={handleLogin}
                        >
                            เข้าสู่ระบบ
                        </Button>
                        <Button
                            className='button-register'
                            onClick={handleRegister}
                        >
                            สมัครสมาชิก
                        </Button>
                    </div>
                </Form>
            </div>

        </div>
    );
}

export default LoginScreen;
