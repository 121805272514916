import React, { useState } from "react";
import { Form, Image } from "semantic-ui-react";
import { Button } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { RECAPTCHA_SITE_KEY } from "../constants/Config";
import ReCAPTCHA from "react-google-recaptcha";
import { useLoginMutation, useRegisterMutation, useSendOtpMutation, useVerifyOtpMutation } from "../services/AuthService";

const RegisterScreen = () => {

    const [tel, setTel] = useState("")
    const [recaptcha, setRecaptchaValue] = useState("")
    const [register_step, setRegisterStep] = useState(1)
    const [register_title, setRegisterTitle] = useState("ขอ OTP")
    const [show_msg_validate, setShowMsgValidate] = useState(false);
    const [msg_validate, setMsgValidate] = useState("");
    const [otp_code, setOtpCode] = useState("")
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [otpRef, setOtpRef] = useState("");
    const [otpVerifyID, setOtpVerifyID] = useState("");

    const [sendOtp, result_send_otp] = useSendOtpMutation()
    const [verifyOtp, result_verify_otp] = useVerifyOtpMutation()
    const [register, result_register] = useRegisterMutation()
    const [login, result_login] = useLoginMutation()

    const navigate = useNavigate()

    const handleRegister = async (event) => {
        event.preventDefault();
        try {
            if (register_step === 1) { // ขอ otp
                const _result_otp = await sendOtp({
                    phoneNumber: tel,
                    captcha: recaptcha
                }).unwrap()
                if (_result_otp.status === "0000") {
                    console.log(_result_otp?.data)
                    setOtpRef(_result_otp?.data?.refcode)
                    setRegisterTitle("ยืนยัน OTP")
                    setRegisterStep(2)
                    setShowMsgValidate(false)
                } else {
                    setShowMsgValidate(true)
                    setMsgValidate(_result_otp?.message)
                }
            } else if (register_step === 2) { // ยืนยัน otp
                const _result_verify = await verifyOtp({
                    phoneNumber: tel,
                    verifycode: otp_code,
                    refcode: otpRef
                }).unwrap()
                if (_result_verify.status === "0000") {
                    setOtpVerifyID(_result_verify?.data?._id)
                    setRegisterStep(3)
                    setRegisterTitle("สมัครสมาชิก")
                    setShowMsgValidate(false)
                } else {
                    setMsgValidate(_result_verify?.message)
                    setShowMsgValidate(true)
                }

            } else if (register_step === 3) { // สมัคร
                console.log(otpVerifyID)
                const _result_register = await register({
                    username: tel,
                    password: password,
                    otpVerifyID: otpVerifyID
                }).unwrap()
                if (_result_register.status === "0000") {
                    setShowMsgValidate(false)
                    const _result_login = await login({
                        username: tel,
                        password: password
                    }).unwrap()
                    if (_result_login.message === "success") {
                        if (localStorage.getItem("orderId")) {
                            navigate("/sale/" + localStorage.getItem("orderId"), { replace: true })
                        } else {
                            navigate("/", { replace: true })
                        }
                    } else {
                        setMsgValidate(_result_login.message)
                        setShowMsgValidate(true)
                    }
                } else {
                    setMsgValidate(_result_register?.message)
                    setShowMsgValidate(true)
                }
            }
        } catch (e) {

        }
    }

    const validateForm = () => {
        if (register_step === 1) {
            return tel.length === 10 && !(recaptcha === "" || recaptcha === null);
        }
        if (register_step === 2) {
            return tel.length === 10 && otp_code !== "";
        }
        if (register_step === 3) {
            return tel.length === 10 && password === confirm_password && password.length >= 6 && password.length <= 20;
        }

        return tel.length === 10 && password === confirm_password;
    };

    return <div className="login-container">
        <div className="login-container-content">

            <Image
                src={require("../assets/images/auth/logo_login.png")}
                className="logo-login"
            />

            <div className="register-content">

                <Image
                    src={require("../assets/images/auth/background_content_login.png")}
                    className="login-background"
                />
                <Form
                    loading={
                        result_login.isLoading ||
                        result_register.isLoading ||
                        result_verify_otp.isLoading ||
                        result_send_otp.isLoading
                    }
                >

                    <div className='topic-register'>สมัครสมาชิก</div>

                    <center><p style={{ marginBottom: '5px', color: "red", display: show_msg_validate ? "block" : "none" }}>* {msg_validate}</p></center>
                    <Form.Field style={{ display: register_step === 2 || register_step === 1 ? 'inherit' : 'none' }}>
                        <input className="login-input" disabled={register_step === 2} placeholder='เบอร์โทร' onChange={e => setTel(e.target.value.trim())} value={tel} />
                    </Form.Field>
                    <Form.Field style={{ display: register_step === 1 ? 'inherit' : 'none' }}>
                        <center>
                            <ReCAPTCHA style={{ marginTop: 15, marginBottom: 10 }} size='normal'
                                sitekey={RECAPTCHA_SITE_KEY}
                                onChange={(value) => setRecaptchaValue(value)}
                            />
                        </center>
                    </Form.Field>

                    <Form.Field style={{ display: register_step === 2 ? 'inherit' : 'none' }}>
                        <label>OTP (หมายเลขอ้างอิง : {otpRef})</label>
                        <input className="login-input" placeholder='หมายเลข OTP จาก SMS' type='text' onChange={e => setOtpCode(e.target.value)} />
                        <center><p style={{ color: "orange", marginTop: 5 }}>OTP จะหมดเวลาใน 5นาที</p></center>
                    </Form.Field>
                    <Form.Field style={{ display: register_step === 3 ? 'inherit' : 'none' }}>
                        <input className="login-input" placeholder='ตั้งรหัสผ่าน' type='password' onChange={e => setPassword(e.target.value)} />
                        <p style={{ color: "rgba(201, 155, 112, 1)", marginTop: 10 }}>* รหัสผ่านตัวอักษรตัวเลขหรืออักขระ 6ตัวขึ้นไป</p>
                    </Form.Field>
                    <Form.Field style={{ display: register_step === 3 ? 'inherit' : 'none' }}>
                        <input className="login-input" placeholder='กรอกรหัสผ่านอีกครั้ง' type='password' onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Field>
                    <Button
                        className='button-login'
                        onClick={handleRegister}
                        disabled={!validateForm()}
                    >
                        {register_title}
                    </Button>
                    <Link to={"/"}>
                        <Button
                            className='button-register'
                        >
                            มีไอดีแล้ว
                        </Button>
                    </Link>
                </Form>
            </div>
        </div>
    </div >
}

export default RegisterScreen