import React, { useEffect } from "react";
import { Button, Container, Grid, Image, Segment } from "semantic-ui-react";
import { Header } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOrderDetailMutation } from "../services/OrderService";
import ReactPlayer from 'react-player';

const ResultCardScreen = () => {
    const { orderId } = useParams();

    const navigate = useNavigate()

    const [getOrderDetail, result_order_detail] = useGetOrderDetailMutation()

    const getDetail = async () => {
        try {
            const _result_order = await getOrderDetail(orderId).unwrap()
            if (_result_order.status !== "0000" || _result_order.data === null) {
                navigate("/", { replace: true })
            }
        } catch (e) {
            // console.log(e)
        }
    }

    useEffect(() => {
        getDetail()
    }, [])

    return <Container>
        <div style={{
            paddingBottom: 110,
        }}>
            <Segment
                loading={result_order_detail.isLoading}
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    border: 0,
                    boxShadow: 'unset',
                    background: 'transparent',
                }}>
                <Header>ผลการทำนาย</Header>

                <Grid divided='vertically' centered >
                    <Grid.Row>
                        {
                            result_order_detail?.data?.data?.products[0]?.result?.cards?.map((item) => {
                                const _length = result_order_detail?.data?.data?.products[0]?.result?.cards?.length
                                let _computer = 4
                                let _tablet = 4
                                let _mobile = 6
                                if (_length === 1) {
                                    _computer = 12
                                    _tablet = 12
                                    _mobile = 12
                                } else if (_length > 2) {
                                    _computer = 4
                                    _tablet = 4
                                    _mobile = 6
                                } else {
                                    _computer = 6
                                    _tablet = 6
                                    _mobile = 6
                                }
                                return <>
                                    <Grid.Column computer={_computer} tablet={_tablet} mobile={_mobile}>
                                        <Image
                                            src={item.imageFront}
                                        />
                                        <center
                                            style={{
                                                color: "#fff",
                                                padding: "0px 0px 5px",
                                                fontSize: 18
                                            }}
                                        >
                                            {item.name}
                                        </center>
                                    </Grid.Column>
                                </>
                            })
                        }
                    </Grid.Row>
                </Grid>
                <center
                    style={{
                        color: "#fff",
                        padding: "10px 0px 5px",
                        fontSize: 14
                    }}
                >
                    ดูดวงเปิดไพ่
                </center>
                <div
                    style={{
                        color: "#fff",
                        padding: "0px 0px 5px",
                        fontSize: 18
                    }}
                >
                    {result_order_detail?.data?.data?.products[0]?.result.text}
                </div>
                <div
                    style={{
                        color: "#777777",
                        fontSize: 14
                    }}
                >
                    {result_order_detail?.data?.data?.products[0]?.result.description}
                </div>

                {
                    result_order_detail?.data?.data?.products[0]?.result.video &&
                    <ReactPlayer
                        url={result_order_detail?.data?.data?.products[0]?.result.video}
                        controls={true}
                        width="100%"
                        height={400}
                        playing={true}
                    />
                }
                <Button
                    style={{ marginTop: 30, width: 200 }}
                    onClick={() => {
                        navigate("/")
                    }}>กลับไปหน้าหลัก</Button>

            </Segment>
        </div>
    </Container >
}

export default ResultCardScreen