import { Navigate, createBrowserRouter } from 'react-router-dom';
import {
    SuccessBuyCardScreen,
    SaleScreen,
    ChangePasswordScreen,
    ResultCardScreen,
    RegisterScreen,
    LoginScreen,
    ErrorScreen,
    ProfileScreen,
    OpenCardScreen,
    ListOrderScreen,
    ManageProfileScreen
} from '../screen';
import { AuthLayout } from './AuthLayout';
import { ProtectedLayout } from './ProtectedLayout';
import ForgotPasswordScreen from '../screen/ForgetPasswordScreen';

export default createBrowserRouter([
    {
        element: <AuthLayout />,
        errorElement: <ErrorScreen />,
        children: [
            {
                path: "/login",
                element: <LoginScreen />,
            },
            {
                path: "/register",
                element: <RegisterScreen />,
            },
            {
                path: "/forget-password",
                element: <ForgotPasswordScreen />,
            }
        ]
    },
    {
        path: "/",
        element: <ProtectedLayout />,
        errorElement: <ErrorScreen />,
        children: [
            {
                path: "",
                element: <ListOrderScreen />
            },
            {
                path: "/home",
                element: <ListOrderScreen />
            },
            {
                path: "/success-buy-card/:orderId",
                element: <SuccessBuyCardScreen />
            },
            {
                path: "/sale/:orderId",
                element: <SaleScreen />
            },
            {
                path: "/change-password",
                element: <ChangePasswordScreen />
            },
            {
                path: "/result-card/:orderId",
                element: <ResultCardScreen />
            },
            {
                path: "/profile",
                element: <ProfileScreen />
            },
            {
                path: "/open-card/:orderId/:productId",
                element: <OpenCardScreen />
            },
            {
                path: "/manage-profile",
                element: <ManageProfileScreen />
            },
            {
                path: "*",
                element: <Navigate to="/home" replace />
            }
        ]
    },
]);