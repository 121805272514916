import { createSlice } from '@reduxjs/toolkit'
import { useAppDispatch } from "../StoreHook";
import useConnector from '../mapConnector';

const setInitialState = () => {
    return {
        profile: []
    }
}

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        value: setInitialState()
    },
    reducers: {
        setProfile(state, action) {
            state.value.profile = action.value
        },
    }
})

export const { setProfile } = profileSlice.actions

export const ProfileAction = () => {
    const dispatch = useAppDispatch()
    return {
        setProfile: (data) => dispatch(setProfile(data)),
    }
}

export default profileSlice.reducer

export const useConnectorProfile = (selector) => {
    return useConnector("profile", selector);
};

