import React, { useEffect, useState } from "react";
import { Container, Dimmer, Form, Icon, Loader } from "semantic-ui-react";
import { Button, Header } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPaymentDataMutation } from "../services/OrderService";

const SuccessBuyCardScreen = () => {
    const { orderId } = useParams();
    const navigate = useNavigate()

    const [getPaymentData, result_get_payment] = useGetPaymentDataMutation()
    const [loading, setLoading] = useState(true)

    const getData = async (condition = false) => {
        try {
            const result_payment = await getPaymentData(orderId).unwrap()
            if (result_payment.status !== "0000" || result_payment.data.paymentStatus === "COMPLETED") {
                setLoading(false)
                navigate("/")
            } else if (condition) {
                document.forms['from'].submit();
                navigate("/")
            }
            setLoading(false)
        } catch (error) {

        }
    }

    useEffect(() => {
        getData()
    }, [])


    return <Container>
        <Dimmer active={loading}>
            <Loader>กรุณารอสักครู่</Loader>
        </Dimmer>
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
        }}>

            <Header>สั่งซื้อสำเร็จรอชำระเงิน</Header>
            <Icon name="check circle outline" style={{ color: "#AB8D60", fontSize: 150 }} />
            {
                result_get_payment?.data?.data?.body &&
                <>
                    <Form
                        method="post"
                        action="https://www.thaiepay.com/epaylink/payment.aspx"
                        name="from"
                        onSubmit={() => {
                            setLoading(true)
                            getData(true)
                        }}
                    >
                        {Object.entries(result_get_payment?.data?.data?.body).map(([key, value], index) => {
                            return <input type="hidden" name={key} value={value} key={index} />
                        })}
                        <br />
                        {
                            !loading &&
                            <Button
                                className='button-register'
                                type="submit"
                                name="Submit"
                            >
                                ชำระเงิน
                            </Button>
                        }
                    </Form>
                </>
            }
        </div>
    </Container >
}


export default SuccessBuyCardScreen