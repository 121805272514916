import React, { useState, forwardRef } from "react";
import { Container, Dropdown, Image, Modal, Segment } from "semantic-ui-react";
import { Button, Header, Input } from "."
import { useAddUserMutation } from "../services/UserService";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from 'date-fns/locale/th';
import moment from "moment/moment";
import { birthTimeList, genderList, months } from "../utils/Name";

registerLocale('th', th)

const ModalProfileDetail = ({ isOpen, onClose, onsubmit }) => {

    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [gender, setGender] = useState("")
    const [tel, setTel] = useState("")
    const [birth_date_time, setBirthDateTime] = useState("")
    const [birth_date, setBirthDate] = useState("");
    const [year_full, setYearFull] = useState("");
    const [error, setError] = useState("");

    const [addUser, result_add_user] = useAddUserMutation()

    const handleSubmit = async () => {
        if (
            first_name &&
            last_name &&
            birth_date &&
            gender &&
            tel &&
            birth_date_time
        ) {
            try {
                const _result_add = await addUser({
                    firstName: first_name,
                    lastName: last_name,
                    birthDate: moment(birth_date).format("DD/MM/YYYY"),
                    gender: gender,
                    tel: tel,
                    birthTime: birth_date_time
                }).unwrap()
                if (_result_add.status === "0000") {
                    onsubmit({
                        firstName: first_name,
                        lastName: last_name,
                        birthDate: moment(birth_date).format("DD/MM/YYYY"),
                        gender: gender,
                        tel: tel,
                        birthTime: birth_date_time
                    })
                } else {
                    setError("กรุณากรอกข้อมูลให้ครบถ้วน")
                }
            } catch (error) {
            }
        } else {
            setError("กรุณากรอกข้อมูลให้ครบถ้วน")
        }
    }

    function range(start, end, step) {
        const result = [];
        for (let i = start; i < end; i += step) {
            result.push(i);
        }
        return result;
    }

    const startYear = 1900;
    const currentYear = new Date().getFullYear();
    const years = range(startYear, currentYear + 1, 1);

    const CustomInput = forwardRef(({ value, onClick }, ref) => {
        console.log(value)
        return <button
            className="input-custom"
            onClick={onClick}
            ref={ref}
            style={{ width: "100%", marginBottom: 10, cursor: "pointer" }}
        >
            {value ? moment(value).add(543, "years").format("DD/MM/YYYY") : "เลือกวันเดือนปีเกิด"}
        </button>
    });

    return <Modal
        onClose={onClose}
        open={isOpen}
        style={{
            background: 'transparent',
            maxWidth: 400,
            paddingBottom: 20
        }}
        closeIcon={true}
    >
        <Image
            src={require("../assets/images/auth/background_content_login.png")}
            className="login-background"
        />
        <Container>

            <Header style={{ paddingBottom: 10, paddingTop: 30 }}>เพิ่มข้อมูลผู้ใช้งาน</Header>

            <Segment
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    boxShadow: 'unset',
                    border: 0,
                    marinTop: -10,
                    paddingTop: 0,
                    background: 'transparent'
                }}
                loading={result_add_user.isLoading}
            >

                {
                    error &&
                    <p className="error_message"> * {error} *</p>
                }

                <Input
                    placeholder="ชื่อ"
                    style={{
                        width: "100%",
                        maxWidth: 350,
                        marginBottom: 10
                    }}
                    type="first_name"
                    onChange={(e) => {
                        setError("")
                        setFirstName(e.target.value)
                    }}
                />

                <Input
                    placeholder="นามสกุล"
                    style={{
                        width: "100%",
                        maxWidth: 350,
                        marginBottom: 10
                    }}
                    type="last_name"
                    onChange={(e) => {
                        setError("")
                        setLastName(e.target.value)
                    }}
                />

                <Dropdown
                    placeholder='เพศ'
                    fluid
                    selection
                    options={genderList}
                    className="input-custom"
                    onChange={(e, { value }) => {
                        setError("")
                        setGender(value)
                    }}
                    style={{ maxWidth: 350, marginBottom: 10, textAlign: "center" }}
                />

                <DatePicker
                    locale="th"
                    className="select-date"
                    style={{ maxWidth: 350, marginBottom: 10, textAlign: "center" }}
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                style={{
                                    background: "#a56427",
                                    borderTopLeftRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    border: "1px solid #7c4b1d"
                                }}
                            >
                                {"<"}
                            </button>
                            <Dropdown
                                fluid
                                selection
                                options={years.map((item) => {
                                    return {
                                        key: item,
                                        text: item + 543,
                                        value: item + 543
                                    }
                                })}
                                onChange={(e, { value }) => {
                                    changeYear(value - 543)
                                    setYearFull(value - 543)
                                }}
                                defaultValue={year_full ? year_full + 543 : (new Date().getFullYear()) + 543}
                                style={{
                                    background: "#a56427",
                                }}
                            />

                            <Dropdown
                                fluid
                                selection
                                options={months.map((item) => {
                                    return {
                                        key: item,
                                        text: item,
                                        value: item
                                    }
                                })}
                                onChange={(e, { value }) => {
                                    changeMonth(months.indexOf(value))
                                }}
                                defaultValue={months[date.getMonth()]}
                                style={{
                                    background: "#a56427"
                                }}
                            />
                            <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                style={{
                                    background: "#a56427",
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    border: "1px solid #7c4b1d"
                                }}
                            >
                                {">"}
                            </button>
                        </div>
                    )}
                    selected={birth_date}
                    onChange={(date) => {
                        setError("")
                        setBirthDate(date)
                    }}
                    placeholderText="เลือกวันเดือนปีเกิด"
                    // dateFormat="MM/dd/yyyy"
                    customInput={<CustomInput />}
                />

                <Dropdown
                    placeholder='เวลาเกิด'
                    fluid
                    selection
                    options={birthTimeList}
                    className="input-custom"
                    onChange={(e, { value }) => {
                        setError("")
                        setBirthDateTime(value)
                    }}
                    style={{ maxWidth: 350, marginBottom: 10, textAlign: "center", width: "100%" }}
                />

                <Input
                    placeholder="เบอร์โทรศัพท์"
                    style={{
                        width: "100%",
                        maxWidth: 350,
                        marginBottom: 10
                    }}
                    type="last_name"
                    onChange={(e) => {
                        setError("")
                        setTel(e.target.value)
                    }}
                />

                <Button
                    style={{
                        maxWidth: 330,
                        backgroundColor: "#AB8D60"
                    }}
                    onClick={handleSubmit}
                    disable
                >บันทึก</Button>
            </Segment>

        </Container>
    </Modal >
}

export default ModalProfileDetail