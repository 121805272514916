import React from "react";
import { Button as ButtonDefault } from "semantic-ui-react";

const Button = (props) => {
    return <ButtonDefault
        {...props}
    >
        {props.children}
    </ButtonDefault >
}

export default Button