import React from "react";
import { Link } from "react-router-dom";
import { Icon, Image } from "semantic-ui-react";

const ProfileButton = ({ title, sub_title, image_url, path, style, onClick }) => {

    return (
        <div className="profile-button" style={style}>
            <Image src={image_url} />
            <div style={{ flex: 1, paddingLeft: 15 }}>
                <div style={{ color: "#FFF", fontSize: 16 }}>{title}</div>
                <div style={{ color: "#777777", fontSize: 14 }}>{sub_title}</div>
            </div>
            <Link to={path} onClick={onClick}>
                <Icon name="arrow right" style={{ color: "#E4A951", fontSize: 24 }} />
            </Link>
            <div style={{ height: 1, border: "1px solid rgba(119, 119, 119, 0.3)", width: "100%", position: "absolute", bottom: 0 }} />
        </div>
    )

}

export default ProfileButton