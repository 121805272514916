import React from "react";
import { RouterProvider } from "react-router-dom";
import Route from "./route";
import { Store } from './store'
import { Provider } from 'react-redux'

function App() {


  return (
    <React.StrictMode>
      <Provider store={Store} >
        <RouterProvider router={Route} />
      </Provider>
    </React.StrictMode>
  );
}

export default App;
