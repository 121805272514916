import { createApi } from '@reduxjs/toolkit/query/react';
import {
    ADD_PROFILE,
    CHANGE_PASSWORD,
    DELETE_PROFILE,
    LOG_OUT, USER_ME,

} from '../constants/PathConfig';
import FetchService from "./FetchService";

const UserService = createApi({
    reducerPath: 'UserService',
    baseQuery: FetchService,
    endpoints: (builder) => ({
        logout: builder.mutation({
            query: () => ({
                url: LOG_OUT,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data.message === "success") {
                        localStorage.removeItem("access_token")
                    }
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        userDetail: builder.mutation({
            query: (data) => ({
                url: USER_ME,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        addUser: builder.mutation({
            query: (data) => ({
                url: ADD_PROFILE,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        deleteUser: builder.mutation({
            query: (data) => ({
                url: DELETE_PROFILE,
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                },
                body: data
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        changePassword: builder.mutation({
            query: (data) => ({
                url: CHANGE_PASSWORD,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: data
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),

    }),
});

export const {
    useLogoutMutation,
    useUserDetailMutation,
    useDeleteUserMutation,
    useAddUserMutation,
    useChangePasswordMutation
} = UserService;

export default UserService;
