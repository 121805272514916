import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Image } from "semantic-ui-react";
const BottomTabBar = () => {

    const { pathname } = useLocation()
    const basket_screen = ["", "home", "success-buy-card"]
    const order_screen = ["open-card", "result-card"]
    const profile_screen = ["profile", "change-password"]

    const checkActive = () => {
        const name = pathname.slice(1,).split("/")[0]
        const result_profile = profile_screen.findIndex((item) => item === name)
        const result_order = order_screen.findIndex((item) => item === name)
        const result_basket = basket_screen.findIndex((item) => item === name)
        if (result_basket !== -1) return "basket"
        if (result_order !== -1) return "order"
        if (result_profile !== -1) return "profile"
    }

    return <div className="bottom-tab-bar-container">

        <Container className="tab-bar-content">

            <div className="tab-bar-item-container" >
                <Link to={"/list-order"}>
                    <Image
                        src={checkActive() === "basket" ? require("../assets/images/basket-active-icon.png") : require("../assets/images/basket-icon.png")}
                        className="tab-bar-item-style"
                    />
                </Link>
            </div>

            <div className="tab-bar-item-container" >
                <Image
                    src={checkActive() === "order" ? require("../assets/images/card-active-icon.png") : require("../assets/images/card-icon.png")}
                    className="tab-bar-item-style"
                />
            </div>

            <div className="tab-bar-item-container">
                <Link to={"/profile"}>
                    <Image
                        src={checkActive() === "profile" ? require("../assets/images/profile-active-icon.png") : require("../assets/images/profile-icon.png")}
                        className="tab-bar-item-style"
                    />
                </Link>
            </div>

        </Container >
    </div >
}

export default BottomTabBar