import React, { useEffect } from "react";
import { CardOrder, Header } from "../components";
import { useNavigate } from "react-router-dom";
import { useGetOrderListMutation } from "../services/OrderService";
import { Segment } from "semantic-ui-react";

const ListOrderScreen = () => {

    const navigate = useNavigate()

    useEffect(() => {
        getData()
    }, [])


    const getData = async () => {
        try {
            await getOrderList().unwrap()
        } catch {
        }
    }
    const [getOrderList, result_my_order] = useGetOrderListMutation()

    return <Segment
        loading={result_my_order?.isLoading}
        style={{ boxShadow: 'unset', border: 0, background: 'transparent', paddingBottom: 110 }}
    >
        <Header style={{ paddingTop: 10 }}>รายการที่สั่งซื้อ</Header>

        {
            result_my_order?.data?.data?.length > 0 ?
                result_my_order?.data?.data?.map((item, index) => {
                    if (item?.products[0]?.type === "CARD") {
                        return <CardOrder
                            key={index}
                            title={"ดูดวงเปิดไพ่" + item?.products[0]?.name}
                            from={item?.products[0]?.teacherName || "xxxxx"}
                            user={item.deliveryDetail.firstName}
                            onClick={() => {
                                if (item.paymentStatus === "COMPLETED" && item.deliveryStatus === "COMPLETED" && item.products[0].isPlay) {
                                    navigate("/result-card/" + item?._id)
                                } else if (item.paymentStatus === "COMPLETED" && item.deliveryStatus === "COMPLETED" && !item.products[0].isPlay) {
                                    navigate(`/open-card/${item?._id}/${item?.products[0]._id}`)
                                } else {
                                    navigate(`/success-buy-card/${item?._id}`)
                                }
                            }}
                            buttonName={
                                item.paymentStatus === "PENDING" ? "ชำระเงิน"
                                    :
                                    (item.paymentStatus === "COMPLETED" && item.deliveryStatus === "COMPLETED" && item.products[0].isPlay)
                                        ?
                                        "ดูผลการเปิดไพ่"
                                        :
                                        ((item.paymentStatus === "COMPLETED" && item.deliveryStatus === "COMPLETED" && !item.products[0].isPlay)
                                            &&
                                            "เปิดไพ่"
                                        )
                            }
                        />
                    }
                    else {
                        return null
                    }
                })
                :
                <center style={{
                    color: "#fff",
                    fontSize: 18
                }}>{result_my_order?.isLoading ? "กำลังโหลด" : "ไม่มีรายการที่สั่งซื้อ"}</center>
        }
    </Segment>
}

export default ListOrderScreen