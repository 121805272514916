import { createApi } from '@reduxjs/toolkit/query/react';
import {
    ALL_PRODUCT,
    PRODUCT_DETAIL,
} from '../constants/PathConfig';
import FetchService from "./FetchService";

const ProductService = createApi({
    reducerPath: 'ProductService',
    baseQuery: FetchService,
    endpoints: (builder) => ({
        getProductList: builder.mutation({
            query: (data) => ({
                url: ALL_PRODUCT,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
        getProductDetail: builder.mutation({
            query: (data) => ({
                url: PRODUCT_DETAIL,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                } catch (err) {
                    console.log({ err });
                }
            },
        }),
    }),
});

export const {
    useGetProductDetailMutation,
    useGetProductListMutation
} = ProductService;

export default ProductService;
