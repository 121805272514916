import { Navigate, Outlet, useParams } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { BottomTabBar } from "../components";
import { useLocation } from "react-router-dom";

export const ProtectedLayout = () => {
    const location = useLocation()
    const { orderId } = useParams()
    const token = localStorage.getItem("access_token")
    if (location.pathname.split("/")[1] === "sale") {
        localStorage.setItem("orderId", orderId)
    }
    if (!token) {
        return <Navigate to="/login" />;
    }

    return (
        <div>
            <Container>
                <Outlet />
            </Container>
            <BottomTabBar />
        </div>
    );
};