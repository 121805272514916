import React from "react";
import { Header } from "../components";
import ProfileButton from "../components/ProfileButton";
import { useLogoutMutation } from "../services/UserService";
import { useNavigate } from "react-router-dom";

const ProfileScreen = () => {
    const navigate = useNavigate()

    const [logout] = useLogoutMutation()

    const handleLogout = async () => {
        try {
            const result = await logout().unwrap()
            if (result.status === "0000") {
                navigate("/login")
            }
        } catch (error) {

        }
    }
    return <div style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
    }}
    >
        <Header>โปรไฟล์</Header>
        <ProfileButton
            title={"เปลี่ยนรหัสผ่าน"}
            sub_title={"กดที่นี่หากต้องการเปลี่ยนรหัสผ่าน"}
            path={"/change-password"}
            image_url={require("../assets/images/change_password_icon.png")}
            style={{ marginBottom: 20 }}
        />
        <ProfileButton
            title={"จัดการผู้ใช้งาน"}
            sub_title={"กดที่นี่เพื่อจัดการข้อมูลผู้ใช้งาน"}
            image_url={require("../assets/images/profile-active-icon.png")}
            path={"/manage-profile"}
            style={{ marginBottom: 20 }}
        />
        <ProfileButton
            title={"ออกจากระบบ"}
            sub_title={"กดที่นี่เพื่อออกจากระบบ"}
            image_url={require("../assets/images/logout_icon.png")}
            onClick={handleLogout}
        />
    </div>
}

export default ProfileScreen