import {
    fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { BASE_PATH } from '../constants/PathConfig';
import { useNavigate } from 'react-router-dom';

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_PATH,
    prepareHeaders: async (headers) => {
        const token = await localStorage.getItem("access_token");
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
});

const FetchService = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        localStorage.removeItem("access_token")
        window.location.href = '/login';
    }
    return result
}
export default FetchService;

