import { configureStore } from '@reduxjs/toolkit'
import {
    AuthService,
    UserService,
    GameService,
    OrderService,
    ProductService
} from "../services";
import AuthFormSlice from "./slices/AuthFormSlice";
import ProfileSlice from './slices/ProfileSlice';

const store = configureStore({
    reducer: {
        authForm: AuthFormSlice,
        profile: ProfileSlice,
        [AuthService.reducerPath]: AuthService.reducer,
        [UserService.reducerPath]: UserService.reducer,
        [GameService.reducerPath]: GameService.reducer,
        [OrderService.reducerPath]: OrderService.reducer,
        [ProductService.reducerPath]: ProductService.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(
                AuthService.middleware,
                UserService.middleware,
                GameService.middleware,
                OrderService.middleware,
                ProductService.middleware,
            );
    },
});

export default store;