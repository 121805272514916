export const genderList = [
    {
        key: 'ชายแท้',
        text: 'ชายแท้',
        value: 'M',
    },
    {
        key: 'หญิงแท้',
        text: 'หญิงแท้',
        value: 'F',
    },
    {
        key: 'อยากเป็นชาย',
        text: 'อยากเป็นชาย',
        value: 'NM',
    },
    {
        key: 'อยากเป็นหญิง',
        text: 'อยากเป็นหญิง',
        value: 'NF',
    },
]

export const birthTimeList = [
    {
        key: 'วันจันทร์ (เกิดจันทร์เวลา 6 โมงเช้า - อังคาร เวลา ตี 5.59 น.)',
        text: 'วันจันทร์ (เกิดจันทร์เวลา 6 โมงเช้า - อังคาร เวลา ตี 5.59 น.)',
        value: '1',
    },
    {
        key: 'วันอังคาร (เกิดอังคารเวลา 6 โมงเช้า - พุธเวลา ตี 5.59 น.)',
        text: 'วันอังคาร (เกิดอังคารเวลา 6 โมงเช้า - พุธเวลา ตี 5.59 น.)',
        value: '2',
    },
    {
        key: 'วันพุธ-กลางวัน (เกิดพุธเวลา 6 โมงเช้า - พุธเวลา 17.59 น.)',
        text: 'วันพุธ-กลางวัน (เกิดพุธเวลา 6 โมงเช้า - พุธเวลา 17.59 น.)',
        value: '3',
    },
    {
        key: 'วันพุธ-กลางคืน (เกิดพุธเวลา 6 โมงเย็น - พฤหัสบดีเวลา ตี 5.59 น.)',
        text: 'วันพุธ-กลางคืน (เกิดพุธเวลา 6 โมงเย็น - พฤหัสบดีเวลา ตี 5.59 น.)',
        value: '4',
    },
    {
        key: 'วันพฤหัสฯ (เกิดพฤหัสบดีเวลา 6 โมงเช้า - ศุกร์เวลา ตี 5.59 น.)',
        text: 'วันพฤหัสฯ (เกิดพฤหัสบดีเวลา 6 โมงเช้า - ศุกร์เวลา ตี 5.59 น.)',
        value: '5',
    },
    {
        key: 'วันศุกร์ (เกิดศุกร์เวลา 6 โมงเช้า - เสาร์เวลา ตี 5.59 น.)',
        text: 'วันศุกร์ (เกิดศุกร์เวลา 6 โมงเช้า - เสาร์เวลา ตี 5.59 น.)',
        value: '6',
    },
    {
        key: 'วันเสาร์ (เกิดเสาร์เวลา 6 โมงเช้า - อาทิตย์เวลา ตี5.59 น.)',
        text: 'วันเสาร์ (เกิดเสาร์เวลา 6 โมงเช้า - อาทิตย์เวลา ตี5.59 น.)',
        value: '7',
    },
    {
        key: 'วันอาทิตย์ (เกิดอาทิตย์เวลา 6 โมงเช้า - จันทร์เวลา ตี 5.59 น.)',
        text: 'วันอาทิตย์ (เกิดอาทิตย์เวลา 6 โมงเช้า - จันทร์เวลา ตี 5.59 น.)',
        value: '8',
    },
]

export const months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
];